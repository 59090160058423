.border-dotted {
  border-style: dotted;
}
.border-2 {
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  border-color: rgba(0, 0, 0, 0.3);
}
.border-for-bottom {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}
.extra-small-font {
  font-size: x-small;
}
.very-small-font {
  font-size: small;
}
.small-font {
  font-size: 0.83em;
}
