@CHARSET "UTF-8";
.sbHolder{
	background-color: #2d2d2d;
	border: solid 1px #515151;
	font-family: Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	height: 30px;
	position: relative;
	width: 200px;
}
.sbHolder:focus .sbSelector{
	
}
.sbSelector{
	display: block;
	height: 30px;
	left: 0;
	line-height: 30px;
	outline: none;
	overflow: hidden;
	position: absolute;
	text-indent: 10px;
	top: 0;
	width: 170px;
}
.sbSelector:link, .sbSelector:visited, .sbSelector:hover{
	color: #EBB52D;
	outline: none;
	text-decoration: none;
}
.sbToggle{
	background: url(../images/select-icons.png) 0 -116px no-repeat;
	display: block;
	height: 30px;
	outline: none;
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
}
.sbToggle:hover{
	background: url(../images/select-icons.png) 0 -167px no-repeat;
}
.sbToggleOpen{
	background: url(../images/select-icons.png) 0 -16px no-repeat;
}
.sbToggleOpen:hover{
	background: url(../images/select-icons.png) 0 -66px no-repeat;
}
.sbHolderDisabled{
	background-color: #3C3C3C;
	border: solid 1px #515151;
}
.sbHolderDisabled .sbHolder{
	
}
.sbHolderDisabled .sbToggle{
	
}
.sbOptions{
	background-color: #212121;
	border: solid 1px #515151;
	list-style: none;
	left: -1px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 30px;
	width: 200px;
	z-index: 5;
	overflow-y: auto;
}
.sbOptions li{
	padding: 0 7px;
}
.sbOptions li:hover {
	background-color: #f0f0f0;
}
.sbOptions a{
	border-bottom: dotted 1px rgba(81, 81, 81, 0.1);
	display: block;
	outline: none;
	padding: 7px 0 7px 3px;
}
.sbOptions a:link, .sbOptions a:visited{
	color: #ddd;
	text-decoration: none;
}
.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus{
	color: #EBB52D;
}
.sbOptions li.last a{
	border-bottom: none;
}
.sbOptions .sbDisabled{
	border-bottom: dotted 1px #515151;
	color: #999;
	display: block;
	padding: 7px 0 7px 3px;
}
.sbOptions .sbGroup{
	border-bottom: dotted 1px #515151;
	color: #EBB52D;
	display: block;
	font-weight: bold;
	padding: 7px 0 7px 3px;
}
.sbOptions .sbSub{
	padding-left: 17px;
}